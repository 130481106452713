import template from './collectionsForm.html';
import './collectionsForm.scss';

import instagramIcon from '../../../../assets/images/fa-instagram.svg';
import twitterIcon from '../../../../assets/images/fa-twitter.svg';
import moderationServicesImage from '../../../../assets/images/moderation-services.png';
import collectionsManagerTitle from '../../../../assets/images/collectionsManagerTitle.svg';

/**
 * @ngdoc controller
 * @name CollectionsForm
 * @description
 * This component displays the collections form section.
 *
 * @memberof collections
 */
class CollectionsForm {
  /**
   * @param {$timeout}   $timeout                    To wait some time before clear the stream search.
   * @param {Moment}     moment                      To perform date manipulation.
   * @param {UIMessages} uiMessages                  To display the error messages.
   * @param {Object}     COLLECTIONS_BASE_TYPES      To get the base types configuration.
   * @param {Object}     COLLECTIONS_RULE_OPERATORS  To get the rule operators configuration.
   * @param {Object}     COLLECTIONS_RULE_TYPES      To get the rule types configuration.
   */
  constructor(
    $timeout,
    moment,
    uiMessages,
    COLLECTIONS_BASE_TYPES,
    COLLECTIONS_RULE_OPERATORS,
    COLLECTIONS_RULE_TYPES,
  ) {
    'ngInject';

    /**
     * The local reference to the `$timeout` service.
     *
     * @type {$timeout}
     */
    this.$timeout = $timeout;
    /**
     * The local reference to the `moment` service.
     *
     * @type {Moment}
     */
    this.moment = moment;
    /**
     * The local reference to the `uiMessages` service.
     *
     * @type {UIMessages}
     */
    this.uiMessages = uiMessages;
    /**
     * The local reference to the `COLLECTIONS_BASE_TYPES` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_BASE_TYPES = COLLECTIONS_BASE_TYPES;
    /**
     * The local reference to the `COLLECTIONS_RULE_OPERATORS` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_RULE_OPERATORS = COLLECTIONS_RULE_OPERATORS;
    /**
     * The local reference to the `COLLECTIONS_RULE_TYPES` constant.
     *
     * @type {Object}
     */
    this.COLLECTIONS_RULE_TYPES = COLLECTIONS_RULE_TYPES;
    /**
     * The local reference for the Flatpickr's instances provided by the Ods Calendar component.
     * It will manage two instances (one per calendar input: Date From & Date To).
     *
     * @type {Map<Flatpickr>}
     */
    this.flatpickrInstances = {};
    /**
     * Reference to the InstagramIcon image.
     *
     * @type {string}
     */
    this.instagramIcon = instagramIcon;
    /**
     * Reference to the TwitterIcon image.
     *
     * @type {string}
     */
    this.twitterIcon = twitterIcon;
    /**
     * The local reference to the moderation image displayed under `Moderation Services` option.
     *
     * @type {string}
     */
    this.moderationServicesImage = moderationServicesImage;
    /**
     * Reference to the title image.
     *
     * @type {string}
     */
    this.collectionsManagerTitle = collectionsManagerTitle;
    /**
     * The hashtags to use when adding a new hashtag include rule to the collection.
     *
     * @type {string}
     */
    this.includeHashtags = '';
    /**
     * Flag to indicate if the hashtag input has errors.
     *
     * @type {boolean}
     */
    this.includeHashtagsError = false;
    /**
     * The hashtags to use when adding a new hashtag exclude rule to the collection.
     *
     * @type {string}
     */
    this.excludeHashtags = '';
    /**
     * Flag to indicate if the hashtag input has errors.
     *
     * @type {boolean}
     */
    this.excludeHashtagsError = false;
    /**
     * Keyword events, listen when the user press one
     * of the following keys when adding hashtags.
     *
     * @type {Object}
     */
    this.keyEvents = {
      tab: 9,
      enter: 13,
      escape: 27,
    };
    /**
     * The rule template to use when adding a new rule to the collection.
     *
     * @type {Object}
     */
    this.ruleTemplate = {
      operator: null,
      type: null,
      value: '',
      error: '',
    };
    /**
     * The search text to search for streams or create a new one.
     *
     * @type {string}
     */
    this.search = '';
    /**
     * The stream to use when adding a new stream to the collection.
     *
     * @type {Object}
     */
    this.stream = angular.copy(this.streamTemplate);
    /**
     * The stream template to use when adding a new stream to the collection.
     *
     * @type {Object}
     */
    this.streamTemplate = {
      id: 0,
      name: '',
      backName: '',
    };
    /**
     * Flag to disable the `Send to Olapic Moderation Services` option.
     *
     * @type {boolean}
     */
    this.disableSendModerationServices = false;
    /**
     * @ignore
     */
    this.validateCollectionName = this.validateCollectionName.bind(this);
    /**
     * @ignore
     */
    this.validateHashtagLimit = this.validateHashtagLimit.bind(this);
    /**
     * The validation masks to compare with hashtags and usernames inputs.
     *
     * @type {Object}
     */
    this.validationMasks = {
      hashtagsAllowedCharsRegExp: /^([#\w,\s]|[#\w,#])*$/,
      hashtagsInvalidCharPositionRegExp: /([\w|#][#])/,
      hashtagsInvalidCharRegExp: /^(#\s)+/,
    };
    /**
     * Flag to indicate if the collection is created for an unlimited time period or not.
     *
     * @type {boolean}
     */
    this.unlimitedTimePeriod = true;
    /**
     * The local reference for the 'from' calendar options.
     *
     * @type {Object}
     */
    this.fromCalendarOptions = {};
    /**
     * The local reference for the 'to' calendar options.
     *
     * @type {Object}
     */
    this.toCalendarOptions = {};
    /**
     * The local reference for the base calendar options.
     *
     * @type {Object}
     * @access protected
     */
    this._defaultCalendarOptions = {
      showMonths: 1,
      mode: 'single',
      enableTime: false,
      dateFormat: 'm/d/Y',
      allowInput: false,
    };
  }
  /**
   * Set the initial calendars options, default dates and the min date.
   */
  $onInit() {
    this.fromCalendarOptions = angular.copy(this._defaultCalendarOptions);
    this.toCalendarOptions = angular.copy(this._defaultCalendarOptions);

    this.unlimitedTimePeriod = !(this.collection.ends_at);

    const startDate = this.collection.starts_at ? new Date(Date.parse(this.collection.starts_at)) : null;
    const endDate = this.collection.ends_at ? new Date(Date.parse(this.collection.ends_at)) : null;

    if (startDate) {
      this.fromCalendarOptions.defaultDate = startDate;
      this.toCalendarOptions.minDate = this._getDateToMinDate(startDate);
    }

    if (endDate) {
      this.toCalendarOptions.defaultDate = endDate;
    }
  }
  /**
   * Each time the collection binding changes, call the `checkDisableSendModerationServices` method.
   * Each time the socialMentionsAccounts binding changes, map the social mentions accounts.
   *
   * @param {Object} changes                         The binding changes.
   * @param {Object} changes.collection              The collection change object.
   * @param {Object} changes.socialMentionsAccounts  The socialMentionsAccounts change object.
   */
  $onChanges({ collection, socialMentionsAccounts }) {
    if (
      collection &&
      collection.currentValue
    ) {
      this.checkDisableSendModerationServices();
    }

    if (
      socialMentionsAccounts &&
      socialMentionsAccounts.currentValue &&
      this.socialMentionsAccounts
    ) {
      // Format the social mentions accounts because we need them in a certain format for the ods dropdown.
      // Also filter the accounts that are duplicated by using a Map
      const uniqueSocialMentionsAccounts = this.socialMentionsAccounts
      .reduce((acc, socialMentionsAccount) => acc.set(socialMentionsAccount.username, {
        id: socialMentionsAccount.username,
        name: `@${socialMentionsAccount.username}`,
      }), new Map())
      .values();

      this.socialMentionsAccounts = Array.from(uniqueSocialMentionsAccounts);

      // If there is no social mentions account, add a link to configure them.
      if (!this.socialMentionsAccounts.length) {
        this.socialMentionsAccounts.push({
          name: 'Configure your social accounts',
          type: 'link',
        });
      }
    }
    if (
      collection &&
      collection.currentValue &&
      this.flatpickrInstances.dateFrom &&
      this.flatpickrInstances.dateTo
    ) {
      this.flatpickrInstances.dateFrom.setDate(this.collection.starts_at, false);
      this.flatpickrInstances.dateTo.setDate(this.collection.ends_at, false);
    }
  }
  /**
   * Add the hashtag to the collection.
   */
  addHashtag() {
    // Process include hashtags
    if (this._validateHashtagsList(this.includeHashtags)) {
      const sanitizedHashtagsList = this._sanitizeHashtags(this.includeHashtags);
      const newIncludeRules = sanitizedHashtagsList.map((hashtag) => ({
        operator: this.COLLECTIONS_RULE_OPERATORS.with,
        type: this.COLLECTIONS_RULE_TYPES.hashtag,
        value: hashtag,
        error: '',
      }));
      this.collection.rules = [...this.collection.rules, ...newIncludeRules];
      this.collection.includes = [...this.collection.includes, ...sanitizedHashtagsList];
      this.includeHashtags = '';
    }

    // Process exclude hashtags
    if (this._validateHashtagsList(this.excludeHashtags)) {
      const sanitizedHashtagsList = this._sanitizeHashtags(this.excludeHashtags);
      const newExcludeRules = sanitizedHashtagsList.map((hashtag) => ({
        operator: this.COLLECTIONS_RULE_OPERATORS.without,
        type: this.COLLECTIONS_RULE_TYPES.hashtag,
        value: hashtag,
        error: '',
      }));
      this.collection.rules = [...this.collection.rules, ...newExcludeRules];
      this.collection.excludes = [...this.collection.excludes, ...sanitizedHashtagsList];
      this.excludeHashtags = '';
    }
  }
  /**
   * Check if create stream action should be enabled or not.
   *
   * @returns {boolean}
   */
  checkCreateEnabled() {
    if (this.stream && this.stream.suggestions) {
      return !this.stream
      .suggestions.some((suggestion) => suggestion.name.toLowerCase() === this.search.toLowerCase());
    }
    return false;
  }
  /**
   * Check if we need to disable the `Send to Olapic Moderation Services` option.
   * If we are gonna disable this option, then set to skip moderation.
   */
  checkDisableSendModerationServices() {
    this.disableSendModerationServices = (
      !this.hasModerationServices && !this.hasVideoModerationServices
    ) || (
      !this.hasModerationServices && this.collection.mediaTypes.image && !this.collection.mediaTypes.video
    ) || (
      !this.hasVideoModerationServices && this.collection.mediaTypes.video && !this.collection.mediaTypes.image
    );

    if (this.disableSendModerationServices) {
      this.collection.sendToModeration = false;
    }
  }
  /**
   * Check if a control on the form is in error state.
   *
   * @param {Object} form         The form to check.
   * @param {Object} controlName  The name of the control to check.
   *
   * @returns {boolean}
   */
  isInError(form, controlName) {
    const control = form[controlName];

    return control ? (
      form.$submitted ||
      control.$dirty
    ) && control.$invalid :
      false;
  }
  /**
   * When a new base mention is selected, we set that mention as selected.
   *
   * @param {Object} baseMention  The mention to set as selected.
   */
  onBaseMentionSelected(baseMention) {
    if (baseMention.id) {
      this.collection.base.data.mention = baseMention;
    } else {
      this.onGoToSocialAccounts();
    }
  }
  /**
   * When a new base type is selected, we set that type as selected.
   *
   * @param {Object} baseType  The base type to set as selected.
   */
  onBaseTypeSelected(baseType) {
    this.collection.base.type = baseType;

    if (this.collection.base.data.value) {
      // Adjust the value to the maxlength allowed
      this.collection.base.data.value = this.collection.base.data.value.substring(0, baseType.maxlength);
    }

    this.validateCollectionRules();
  }
  /**
   * Callback for the Ods Calendar's component.
   *
   * @param {Date}      selectedDates  The date Object provided by the plugin.
   * @param {string}    dateStr        The date string provided by the plugin.
   * @param {Flatpickr} instance       The flatpickr's intance returned by the plugin.
   * @param {string}    calendar       The calendar instance identifier.
   */
  onCalendarInstanceReady(selectedDates, dateStr, instance, calendar) {
    this.flatpickrInstances[calendar] = instance;
  }
  /**
   * Callback for when selecting a date in the Calendar.
   *
   * @param {Date}      selectedDates  The date Object provided by the plugin.
   * @param {string}    dateStr        The date string provided by the plugin.
   * @param {Flatpickr} instance       The flatpickr's intance returned by the plugin.
   * @param {string}    calendar       The calendar instance identifier.
   */
  onDateChange(selectedDates, dateStr, instance, calendar) {
    let date = dateStr ? this.moment(selectedDates[0]) : null;
    const isDateFrom = calendar === 'dateFrom';
    this.flatpickrInstances[calendar] = instance;

    if (date) {
      if (isDateFrom) {
        date.startOf('day');
        const minDate = this._getDateToMinDate(date.toDate());
        this.flatpickrInstances.dateTo.set('minDate', minDate);
        this.flatpickrInstances.dateTo.setDate(minDate, this.onDateChange);
        date = date.toDate();
        this.collection.starts_at = date;
      } else {
        date.endOf('day');
        date = date.toDate();
        this.collection.ends_at = date;
      }
    } else if (isDateFrom) {
      this.flatpickrInstances.dateTo.set('minDate', null);
    }
  }
  /**
   * Clear the hashtag exclude model.
   */
  onHashtagExcludeBlur() {
    if (!this.excludeHashtagsError) {
      this.excludeHashtags = '';
    }
  }
  /**
   * Called when there is a change in hashtag input.
   * It validates the hashtag is valid for each case and.
   */
  onHashtagExcludeChange() {
    this.excludeHashtagsError = !this._validateHashtagsList(this.excludeHashtags);
  }
  /**
   * Clear the hashtag include model.
   */
  onHashtagIncludeBlur() {
    if (!this.includeHashtagsError) {
      this.includeHashtags = '';
    }
  }
  /**
   * Called when there is a change in hashtag input.
   * It validates the hashtag is valid for each case and.
   */
  onHashtagInputChange() {
    this.includeHashtagsError = !this._validateHashtagsList(this.includeHashtags);
    this.excludeHashtagsError = !this._validateHashtagsList(this.excludeHashtags);
  }
  /**
   * Evaluate the keyCode the user is typing to fire:
   * - An add hashtag when the `enter` or `tab` is typed.
   * - Clear the model when the `escape` is typed.
   *
   * @param {Event} event  The key event.
   */
  onHashtagKeyDown(event) {
    switch (event.which) {
    case this.keyEvents.enter:
    case this.keyEvents.tab:
      this.addHashtag();
      break;
    case this.keyEvents.escape:
      this.includeHashtags = '';
      this.excludeHashtags = '';
      break;
    default:
      break;
    }
  }
  /**
   * Check that Video media type is not selected for Twitter.
   */
  onMediaFromChange() {
    // If mediaFrom is Twitter, then set only image as media type
    // and init baseType to 'profile'
    if (this.collection.mediaFrom === 'twitter') {
      this.collection.mediaTypes.image = true;
      this.collection.mediaTypes.video = false;
      this.onBaseTypeSelected(this.COLLECTIONS_BASE_TYPES.handler);
    } else {
      this.onBaseTypeSelected(this.COLLECTIONS_BASE_TYPES.mention);
    }
    this.checkDisableSendModerationServices();
  }
  /**
   * Check that there is always at least one media type selected.
   *
   * @param {string} mediaType  The media type to check.
   */
  onMediaTypeChange(mediaType) {
    this.collection.mediaTypes[mediaType] = !this.collection.mediaTypes[mediaType];
  }
  /**
   * When a new rule type is selected, we set that type as selected.
   *
   * @param {Object}             rule  The rule to set the type to.
   * @param {CollectionBaseType} type  The rule type to set as selected.
   */
  onRuleTypeSelected(rule, type) {
    rule.type = type;

    if (rule.value) {
      // Adjust the value to the maxlength allowed.
      rule.value = rule.value.substring(0, type.maxlength);
    }

    this.validateCollectionRules();
  }
  /**
   * Clear the stream search on blur of the search box.
   * A $timeout is being used to give time for the onSuggestionClick click to take effect.
   *
   * @param {Object} stream  The stream to clear the search.
   */
  onSearchBlur(stream) {
    const waitTime = 150;
    if (stream) {
      this.$timeout(() => {
        stream.name = '';
        delete stream.suggestions;
      }, waitTime);
    }
  }
  /**
   * Create stream when there are not available suggestions.
   */
  onStreamCreation() {
    this.onCreateStream({ stream: this.search }).then((response) => {
      this.collection.streams.push(angular.copy(response));
    });
  }
  /**
   * When a suggestion is selected, we set that suggestion as the current stream.
   *
   * @param {Object} stream      The stream to change.
   * @param {Object} suggestion  The suggestion to set as selected.
   */
  onSuggestionClick(stream, suggestion) {
    // Check if the stream already exists
    const exists = this.collection.streams
    .some((item) => stream !== item && suggestion.id === item.id);

    if (exists) {
      this.uiMessages.notification(
        'Sorry, you\'re already assigning your collected media to this stream.',
        { type: 'error' },
      );
    } else {
      stream.id = suggestion.id;
      stream.name = suggestion.name;
      stream.backName = stream.name;
      delete stream.suggestions;
      this.collection.streams.push(angular.copy(stream));
    }
  }
  /**
   * Changes the state of the flag that tells if the collection is created for an unlimited time period or not.
   */
  onUnlimitedTimeCheckboxToggle() {
    this.unlimitedTimePeriod = !this.unlimitedTimePeriod;
    if (this.unlimitedTimePeriod) {
      this.collection.starts_at = null;
      this.collection.ends_at = null;
    }
  }
  /**
   * Remove a hashtag of the collection.
   *
   * @param {number} index        The index of the hashtag to remove.
   * @param {string} name         The naame of the hashtag to remove.
   * @param {Array}  hashtagList  The hashtag list.
   */
  removeHashtag(index, name, hashtagList) {
    hashtagList.splice(index, 1);
    // also remove it from rules
    const ruleIndex = this.collection.rules.findIndex((rule) => rule.value === name);
    if (ruleIndex > -1) {
      this.collection.rules.splice(ruleIndex, 1);
    }
  }
  /**
   * Remove a stream of the collection.
   *
   * @param {number} index  The index of the stream to remove.
   */
  removeStream(index) {
    this.collection.streams.splice(index, 1);
  }
  /**
   * Check if the form is valid and trigger the onSubmit callback.
   *
   * @param {Object} form    The form to check if valid.
   * @param {Object} $event  The html submit event.
   */
  submit(form, $event) {
    if ($event.submitter.className === 'odsSearchBox_searchWrapper_button') {
      $event.preventDefault();
      form.$setPristine();
    } else if (form.$valid) {
      this.onSubmit({ collection: this.collection });
    }
  }
  /**
   * Trigger the search handler on every searchbox change.
   *
   * @param {string} text  The phrase to search for.
   */
  triggerSearch(text) {
    this.search = text;
    const minSearchLength = 2;
    text = text.trim();
    if (text && text.length > minSearchLength) {
      this.stream = angular.copy(this.streamTemplate);
      this.stream.name = text;
      this.stream.suggestions = [];
      this.onSearchForStreams({ text })
      .then((streams) => {
        if (streams) {
          this.stream.suggestions = streams.map((item) => {
            item.label = item.name;
            return item;
          });
        }
      });
    }
  }
  /**
   * Trigger the onValidateCollectionName callback to validate the collection name.
   *
   * @param {string} collectionName  The collection name to validate.
   *
   * @returns {boolean}
   */
  validateCollectionName(collectionName) {
    this.collection.name = collectionName;
    return this.onValidateCollectionName({ collection: this.collection });
  }
  /**
   * Validate the collection rules.
   * Check that in the the collection base and rules,
   * there is no more than one user profile and no more than one user mention.
   */
  validateCollectionRules() {
    const typeCount = {};
    const baseType = this.collection.base.type ?
      this.collection.base.type.id :
      '';
    typeCount[baseType] = 1;

    this.collection.rules
    .forEach((rule) => {
      const ruleType = rule.type ? rule.type.id : '';

      if (!typeCount[ruleType]) {
        typeCount[ruleType] = 0;
      }
      typeCount[ruleType]++;

      rule.error = (
        ['handler', 'mention'].includes(ruleType) &&
        typeCount[ruleType] > 1
      ) ?
        `Sorry, collections cannot contain more than one ${rule.type.name}.` :
        '';
    });
  }
  /**
   * Trigger the onValidateHashtagLimit callback to validate the hashtag limit.
   *
   * @returns {boolean}
   */
  validateHashtagLimit() {
    return this.onValidateHashtagLimit({ collection: this.collection });
  }
  /**
   * Get the minimum 'dateTo' date to set to the calendar.
   *
   * @param {Date} date  The from date to generate the minimum 'dateTo' date.
   *
   * @returns {Date}
   */
  _getDateToMinDate(date) {
    return this.moment(date).add(1, 'day').toDate();
  }
  /**
   * Parse a list of hashtags in order to fulfill with a hashtag spec.
   *
   * @param {string} hashtagsList  Contains the string with hashtags.
   *
   * @returns {Array}
   */
  _sanitizeHashtags(hashtagsList) {
    return hashtagsList
    .replace(/ /g, ',')
    // Split the list using commas.
    .split(',')
    // Remove any leading and/or trailing spaces from the hashtags.
    .map((hashtag) => hashtag.trim())
    // Remove empty strings.
    .filter(Boolean)
    // Add hashtags char if missing.
    .map((hashtag) => (hashtag.startsWith('#') ? hashtag : `#${hashtag}`));
  }
  /**
   * Validate the user's input in order to set the error message value
   * in case the input is invalid. It Checks:
   * - Only allowed characters (letters, numbers, period and underscore, hash, comma).
   * - No hash allowed at the end or in other position than the beginning of a hashtag.
   *
   * @param {string} hashtagList  Contains the string with hashtags to validate.
   *
   * @returns {boolean}
   *
   * @access protected
   */
  _validateHashtagsList(hashtagList) {
    return this.validationMasks.hashtagsAllowedCharsRegExp.test(hashtagList) &&
      !this.validationMasks.hashtagsInvalidCharPositionRegExp.test(hashtagList) &&
      !this.validationMasks.hashtagsInvalidCharRegExp.test(hashtagList);
  }
}

/**
 * @ngdoc component
 * @name collectionsForm
 * @description
 * This component renders the collections form section.
 *
 * @memberof collections
 */
export default {
  /**
   * The controller class for the component.
   *
   * @type {CollectionsForm}
   */
  controller: CollectionsForm,
  /**
   * The HTML template for the component.
   *
   * @type {string}
   */
  template,
  /**
   * Component bindings.
   *
   * @type {Object}
   * @property {Object}   collection                  The collection that is being created or edited.
   * @property {boolean}  hasModerationServices       If the customer has moderation services active or not.
   * @property {boolean}  hasVideoCollection          If the customer has video collection active or not.
   * @property {boolean}  hasVideoModerationServices  If the customer has video moderation services active or not.
   * @property {Array}    socialMentionsAccounts      The list of social mentions accounts to select from.
   * @property {boolean}  streamsLoading              If there are streams being loaded.
   * @property {Function} onCancel                    Callback for when the form is canceled.
   * @property {Function} onCreateStream              Callback for create a new stream from collections.
   * @property {Function} onDelete                    Callback for when the delete button is clicked. It receives
   *                                                  collection to delete.
   * @property {Function} onGoToSocialAccounts        Callback to go to the social accounts page.
   * @property {Function} onSearchForStreams          Callback to search for streams. It receives the text to search.
   * @property {Function} onSubmit                    Callback for when the submit button is clicked. It receives
   *                                                  collection to save.
   * @property {Function} onValidateCollectionName    Callback to validate a collection name. It receives the
   *                                                  collection to validate.
   * @property {Function} onValidateHashtagLimit      Callback to validate the limit of hasthag collections. It
   *                                                  receives the collection to validate.
   */
  bindings: {
    collection: '<',
    hasModerationServices: '<',
    hasVideoCollection: '<',
    hasVideoModerationServices: '<',
    socialMentionsAccounts: '<',
    streamsLoading: '<',
    onCancel: '&',
    onCreateStream: '&',
    onDelete: '&',
    onGoToSocialAccounts: '&',
    onSearchForStreams: '&',
    onSubmit: '&',
    onValidateCollectionName: '&',
    onValidateHashtagLimit: '&',
  },
};
